import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { Link } from 'gatsby'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa'
import { PostIconsContainer, LinkRed } from '../Styled'

const PostIcons = ({ node, readingTime, className, calendarPos, clockPos }) => (
  <PostIconsContainer
    css={css`
      margin-top: -0.75rem;
      margin-bottom: 0.75rem;
    `}
    className={className}>
    <div>
      <FaRegCalendarAlt size={14} style={calendarPos} />
      {` `}
      {node.date && format(parseISO(node.date), 'MMMM dd, yyyy')}
      <FaRegClock size={14} style={clockPos} />
      <span>{readingTime}</span>
    </div>
    <div>
      <span>Tags:</span>
      {node.tags &&
        node.tags.map((tag) => (
          <span key={tag}>
            <Link
              to={`tags/${tag}`}
              key={tag}
              css={css`
                margin-left: 15px;
                display: inline-block;
              `}>
              <LinkRed>
                <span>{tag}</span>
              </LinkRed>
            </Link>
          </span>
        ))}
    </div>
  </PostIconsContainer>
)

PostIcons.propTypes = {
  node: PropTypes.object.isRequired,
  readingTime: PropTypes.string,
  className: PropTypes.string,
  calendarPos: PropTypes.object,
  clockPos: PropTypes.object,
}

PostIcons.defaultProps = {
  readingTime: ``,
  className: ``,
}

export default PostIcons
