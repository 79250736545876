import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  Header,
  Container,
  Image,
  PostSnippet,
  PostSnippetDescription,
  PostSnippetHeader,
  PostSnippetImage,
  PostSnippetTitle,
  SubHeader,
} from '../Styled'
import PostIcons from '../PostIcons'

const sortPostsByDate = (posts) =>
  [...posts].sort(
    (a, b) =>
      new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date),
  )

const PostList = ({ data, header, subHeader, noTitle, isHomePage }) => {
  const sortedPosts = sortPostsByDate(data.allMarkdownRemark.edges)
  return (
    <Container>
      {!noTitle && (
        <>
          <Header>{header}</Header>
          <SubHeader>{subHeader}</SubHeader>
        </>
      )}
      {isHomePage && <Header>Latest posts</Header>}
      {sortedPosts &&
        sortedPosts.map(({ node }) => (
          <PostSnippet key={node.frontmatter.slug}>
            {/* {node.frontmatter.featuredImage && (
              <PostSnippetImage
                fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                alt="post snippet image"
              />
            )} */}
            <Link to={`posts/${node.frontmatter.slug}`}>
              <PostSnippetTitle
                dangerouslySetInnerHTML={{ __html: node.frontmatter.title }}
              />
            </Link>
            <PostSnippetHeader>
              <div>by David K.</div>
              <Image />
            </PostSnippetHeader>
            <PostSnippetDescription
              dangerouslySetInnerHTML={{ __html: node.excerpt }}
            />
            <PostIcons
              node={node.frontmatter}
              readingTime={node.fields && node.fields.readingTime.text}
              calendarPos={{
                position: `relative`,
                bottom: 3,
                marginRight: `3px`,
              }}
              clockPos={{
                position: `relative`,
                bottom: 2,
                marginLeft: `20px`,
                marginRight: `5px`,
              }}
            />
          </PostSnippet>
        ))}
    </Container>
  )
}

PostList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }),
  header: PropTypes.string,
  subHeader: PropTypes.string,
  noTitle: PropTypes.bool,
  isHomePage: PropTypes.bool,
}

PostList.defaultProps = {
  data: {},
  header: '',
  subHeader: '',
  isHomePage: false,
}

export default PostList
