import React from 'react'
import PropTypes from 'prop-types'
import { IconContext } from 'react-icons'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import GlobalStyles from '../styles/globalStyles'
import PostList from '../components/PostList'
import MainHeader from '../components/MainHeader'
import SEO from '../components/SEO'

const Home = ({ data }) => (
  <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
    <SEO />
    <Layout title="Home">
      <GlobalStyles />
      <MainHeader />
      <PostList data={data} header="All Posts" noTitle isHomePage />
    </Layout>
  </IconContext.Provider>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          excerpt(pruneLength: 500)
          frontmatter {
            title
            date
            seo_title
            slug
            description
            tags
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

Home.propTypes = {
  data: PropTypes.object,
}

export default Home
